import React from "react";
import { graphql } from "gatsby";
import { gql } from "@apollo/client";

import { unwrap } from "../components/WagtailWrapper";

import { Wagtail, SectionFragment } from "../components/Wagtail";

import { TightContent, Section, Content, Frame } from "../components/Layouts";
import { Title } from "../components/Prose";
import { Breadcrumbs } from "../components/SEOTags";
import {
  ArticleCardFragment,
  PersonCardFragment,
  ExperimentCardFragment,
  PresentationCardFragment,
  PublicationCardFragment,
} from "../components/Card";
import { format } from "date-fns";
import { RelatedContent } from "../components/RelatedContent";

export const Component = ({ page, snippets, images }) => (
  <Frame page={page} url={`news/${page?.slug}`} title={page?.title}>
    <Section>
      <Content>
        <header>
          <Breadcrumbs url={"news"} list={"News"} title={page?.title} />
          <Title>{page?.title}</Title>
        </header>
      </Content>
      <TightContent>
        {(page?.goLiveAt || page?.firstPublishedAt) && (
          <div className="flex space-x-1">
            <p>Posted on </p>
            <time dateTime={page?.goLiveAt || page?.firstPublishedAt}>
              {format(
                new Date(
                  (page?.goLiveAt || page?.firstPublishedAt)
                    ?.replace(/-/g, "/")
                    ?.replace(/T.+/, "")
                ),
                "MMMM dd, yyyy"
              )}
            </time>
            {page?.articleAuthors?.length > 0 && (
              <>
                <p>by</p>
                {page?.articleAuthors.map(({ articleAuthor }) => (
                  <p key={articleAuthor.name.replace(/\s+/g, "_")}>
                    {articleAuthor.name}
                  </p>
                ))}
              </>
            )}
          </div>
        )}
        <Wagtail
          sections={page?.sections}
          snippets={snippets}
          images={images}
        />
      </TightContent>
      <RelatedContent page={page} />
    </Section>
  </Frame>
);

export const previewQuery = gql`
  query previewArticlePage($contentType: String!, $token: String!) {
    images {
      id
      title
      src
    }
    snippets {
      __typename
      ... on CallToAction {
        name
        url
        document {
          id
          file
        }
        id
      }
    }
    page(contentType: $contentType, token: $token) {
      id
      slug
      title
      seoTitle
      seoDescription
      ... on ArticlePage {
        ...ArticleCardFragment
        sections {
          ...SectionFragment
        }
        relatedScientificProjects {
          relatedScientificProject {
            ...ExperimentCardFragment
          }
        }
        relatedPresentations {
          relatedPresentation {
            ...PresentationCardFragment
          }
        }
        relatedPublications {
          relatedPublication {
            ...PublicationCardFragment
          }
        }
      }
    }
  }
  ${ArticleCardFragment}
  ${SectionFragment}
  ${PersonCardFragment}
  ${PresentationCardFragment}
  ${ExperimentCardFragment}
  ${PublicationCardFragment}
`;

export const query = graphql`
  query ArticlePage($id: Int) {
    wagtail {
      images {
        id
        title
        src
      }
      snippets {
        __typename
        ... on Wagtail_CallToAction {
          name
          url
          document {
            id
            file
          }
          id
        }
      }
      page(id: $id) {
        id
        slug
        title
        seoTitle
        seoDescription
        ... on Wagtail_ArticlePage {
          ...Wagtail_ArticleCardFragment
          sections {
            ...Wagtail_SectionFragment
          }
          relatedScientificProjects {
            relatedScientificProject {
              ...Wagtail_ExperimentCardFragment
            }
          }
          relatedPresentations {
            relatedPresentation {
              ...Wagtail_PresentationCardFragment
            }
          }
          relatedPublications {
            relatedPublication {
              ...Wagtail_PublicationCardFragment
            }
          }
        }
      }
    }
  }
`;

export default unwrap(Component);
