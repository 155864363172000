import React from "react";

import { Grid, Content } from "../components/Layouts";
import { MinimalHeader } from "../components/MinimalHeader";
import {
  ExperimentCard,
  PresentationCard,
  PublicationCard,
} from "../components/Card";

export const RelatedContent = ({ page }) => {
  const projects = (page?.relatedScientificProjects || [])
    .map(({ relatedScientificProject }) => relatedScientificProject)
    .concat(page?.scientificProjects || [])
    .filter((v, i, a) => a.findIndex((t) => t.slug === v.slug) === i);
  const pubs = (page?.relatedPublications || [])
    .map(({ relatedPublication }) => relatedPublication)
    .concat(page?.publications || [])
    .filter((v, i, a) => a.findIndex((t) => t.slug === v.slug) === i);
  const pres = (page?.relatedPresentations || [])
    .map(({ relatedPresentation }) => relatedPresentation)
    .concat(page?.presentations || [])
    .filter((v, i, a) => a.findIndex((t) => t.slug === v.slug) === i);
  return projects.length > 0 || pubs.length > 0 || pres.length > 0 ? (
    <Content>
      <MinimalHeader subTitle="related" title="Resources" />
      <Grid>
        {projects?.map((relatedScientificProject) => (
          <ExperimentCard
            showType={true}
            key={relatedScientificProject.slug}
            item={relatedScientificProject}
          />
        ))}
        {pubs?.map((relatedPublication) => (
          <PublicationCard
            showType={true}
            key={relatedPublication.slug}
            item={relatedPublication}
          />
        ))}
        {pres.map((relatedPresentation) => (
          <PresentationCard
            showType={true}
            key={relatedPresentation.slug}
            item={relatedPresentation}
          />
        ))}
      </Grid>
    </Content>
  ) : null;
};
